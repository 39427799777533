import runUtil, { STEP_STATE, getStepState } from './runUtil';
import {
  FieldInputBlock,
  RunFieldInputNumberBlock,
  RunFieldInputCustomListBlock,
  RunFieldInputSettingsListBlock,
  Procedure,
  Step,
  StepConditional,
  StepConditionalState,
  Conditional,
  SourceType,
  RunFieldInputMultipleChoiceBlock,
  StepBlock,
  ConditionalValue,
  RunFieldInputConditionalBlock,
  ReleaseSection,
  DraftSection,
  ReleaseStep,
  DraftStep,
  SectionDiffElement,
  StepDiffElement,
  ProcedureDiff,
  RunSection,
  StepSignoffAction,
  StepEndAction,
  WithDiffChange,
  StepConditionalDiffElement,
  ConditionalDiffElement,
  DiffArrayChangeSymbol,
  RunTelemetryBlock,
  ContentBinaryConditionalDiffElement,
  Run,
  DiffField,
  SourceConditionalsMap,
  ContentBinaryConditional,
  ContentTernaryConditionalDiffElement,
  ContentTernaryConditional,
  RunStep,
} from './types/views/procedures';
import { generateStepConditionalId } from './idUtil';
import diffUtil, { ARRAY_CHANGE_SYMBOLS } from './diffUtil';
import {
  evaluate,
  evaluateIsWithinRange,
  evaluateRangeLocation,
  isSupportedOperation,
  RangeLocation,
  SupportedOperation,
} from './math';
import ProcedureGraph from './ProcedureGraph';

export const CONDITIONAL_TYPE = {
  STEP: 'step',
  CONTENT: 'content',
  CONTENT_BINARY: 'content_binary',
  CONTENT_TERNARY: 'content_ternary',
} as const;

export const CONDITIONAL_STATE = {
  PASS: 'pass',
  FAIL: 'fail',
};

export const CONDITIONAL_TERNARY_STATE = {
  PASS: 'pass',
  FAIL_HIGH: 'fail_high',
  FAIL_LOW: 'fail_low',
  FAIL_NO_DATA: 'fail_no_data',
};

type RecordedValues = { [index: number]: { value: ConditionalValue } };

const isEmptyValue = (text) =>
  text === null || text === undefined || text === '';

const stepConditionals = {
  /**
   * Generate a map from every step to the conditionals that target this step.
   *
   * @param procedure - A procedure document.
   * @returns Map results.
   */
  getSourceConditionalsMap: (procedure: Procedure): SourceConditionalsMap => {
    const map: {
      [targetId: string]: { [conditionalId: string]: StepConditional };
    } = {};
    procedure.sections.forEach((section) => {
      section.steps.forEach((step) => {
        if (!step.conditionals) {
          return;
        }
        (step.conditionals as Array<StepConditional>).forEach((conditional) => {
          if (!map[conditional.target_id]) {
            map[conditional.target_id] = {};
          }
          map[conditional.target_id][conditional.id] = conditional;
        });
      });
    });
    return map;
  },

  /**
   * Generate a map from every step to the conditionals that target this step, for a specified version.
   *
   * @param procedure - A procedure document.
   * @param version
   * @returns Map results.
   */
  getSourceConditionalsMapForVersion: (
    procedure: Procedure | ProcedureDiff,
    version: 'old' | 'new' = 'new'
  ): SourceConditionalsMap => {
    const map: {
      [targetId: string]: {
        [conditionalId: string]: StepConditionalDiffElement;
      };
    } = {};

    const sections = procedure.sections as Array<
      ReleaseSection | DraftSection | RunSection | SectionDiffElement
    >;
    const sectionIdMap = diffUtil.getContainerMap(sections, version);
    sections
      .filter((section) => {
        const sectionId = diffUtil.getDiffValue<string>(section, 'id', version);
        return sectionIdMap.has(sectionId);
      })
      .forEach((section) => {
        const steps = section.steps as Array<
          ReleaseStep | DraftStep | StepDiffElement
        >;
        const stepIdMap = diffUtil.getContainerMap(steps, version);
        steps
          .filter((step) => {
            const stepId = diffUtil.getDiffValue<string>(step, 'id', version);
            return stepIdMap.has(stepId);
          })
          .filter((step) => step.conditionals)
          .forEach((step) => {
            (step.conditionals as Array<StepConditional>).forEach(
              (conditional) => {
                const conditionalId = diffUtil.getDiffValue<string>(
                  conditional,
                  'id',
                  version
                );
                const targetId = diffUtil.getDiffValue<string>(
                  conditional,
                  'target_id',
                  version
                );
                if (!map[targetId]) {
                  map[targetId] = {};
                }
                map[targetId][conditionalId] = !diffUtil.isChanged(
                  conditional,
                  'target_id'
                )
                  ? conditional
                  : {
                      ...conditional,
                      target_id: targetId,
                      diff_change_state: ARRAY_CHANGE_SYMBOLS.ADDED,
                    };
              }
            );
          });
      });
    return map;
  },

  /**
   * Gets conditionals that are "old" (any conditonals on the old version of a modified conditional and any conditionals
   * on deleted steps/sections). Used by full diffs to know if conditionals on a target step have been modified or if
   * all conditionals on a target step have been removed for that draft.
   */
  getSourceOldConditionalsMap: (
    procedure: Procedure | ProcedureDiff
  ): SourceConditionalsMap => {
    const version = 'old';
    const map: {
      [targetId: string]: {
        [conditionalId: string]: StepConditionalDiffElement;
      };
    } = {};

    const sections = procedure.sections as Array<
      ReleaseSection | DraftSection | RunSection | SectionDiffElement
    >;
    const sectionIdMap = diffUtil.getContainerMap(sections, version);
    sections
      .filter((section) => {
        const sectionId = diffUtil.getDiffValue<string>(section, 'id', version);
        return sectionIdMap.has(sectionId);
      })
      .forEach((section) => {
        const steps = section.steps as Array<
          ReleaseStep | DraftStep | StepDiffElement
        >;
        const stepIdMap = diffUtil.getContainerMap(steps, version);
        steps
          .filter((step) => {
            const stepId = diffUtil.getDiffValue<string>(step, 'id', version);
            return stepIdMap.has(stepId);
          })
          .filter((step) => step.conditionals)
          .forEach((step) => {
            (step.conditionals as Array<StepConditional>).forEach(
              (conditional: WithDiffChange<StepConditional>) => {
                const isConditionalOnRemovedStep = diffUtil
                  .getDiffValue<string>(step, 'id', version)
                  .endsWith('__removed');
                if (
                  diffUtil.isChanged(conditional, 'target_id') ||
                  isConditionalOnRemovedStep
                ) {
                  const conditionalId = diffUtil.getDiffValue<string>(
                    conditional,
                    'id',
                    version
                  );
                  const targetId = diffUtil.getDiffValue<string>(
                    conditional,
                    'target_id',
                    version
                  );
                  if (!map[targetId]) {
                    map[targetId] = {};
                  }
                  map[targetId][conditionalId] = {
                    ...conditional,
                    target_id: targetId,
                    diff_change_state: ARRAY_CHANGE_SYMBOLS.REMOVED,
                  };
                }
              }
            );
          });
      });
    return map;
  },

  _isNumberBlockFulfilled: (fieldInput: RunFieldInputNumberBlock): boolean => {
    const rule = fieldInput.rule;
    const value = fieldInput.recorded?.value;
    if (isEmptyValue(rule) || isEmptyValue(value)) {
      return false;
    }
    const operator = fieldInput.rule?.op as string;

    // only support rules on a number
    if (isSupportedOperation(operator)) {
      const operand = parseFloat(rule?.value as string);
      return Boolean(
        evaluate(value as number, operand, operator as SupportedOperation)
      );
    } else if (operator === 'range') {
      const min = rule?.range?.min as string;
      const max = rule?.range?.max as string;
      if (isEmptyValue(min) || isEmptyValue(max)) {
        return false;
      }

      const range = {
        min: parseFloat(min),
        max: parseFloat(max),
        include_min: Boolean(rule?.range?.include_min),
        include_max: Boolean(rule?.range?.include_max),
      };
      return evaluateIsWithinRange(value as number, range);
    }
    return false;
  },

  /**
   * For backwards compatibility with procedures created before ternary conditionals were introduced.
   */
  _compareConditionalTernaryStates: (
    conditional:
      | ContentBinaryConditionalDiffElement
      | ContentTernaryConditionalDiffElement,
    state: string
  ): boolean => {
    if (conditional.source_type === CONDITIONAL_TYPE.CONTENT_BINARY) {
      if (state === CONDITIONAL_TERNARY_STATE.PASS) {
        return conditional.state === CONDITIONAL_STATE.PASS;
      }
      return conditional.state === CONDITIONAL_STATE.FAIL;
    }
    if (conditional.source_type === CONDITIONAL_TYPE.CONTENT_TERNARY) {
      return conditional.state === state;
    }
    return false;
  },

  _isTernaryConditionalFulfilled: (
    block: RunFieldInputNumberBlock | RunTelemetryBlock,
    conditional: ContentTernaryConditionalDiffElement
  ) => {
    const range = block.type === 'input' ? block.rule?.range : block.range;

    if (!range || isEmptyValue(range)) {
      return false;
    }
    const value = block.recorded?.value;
    const min = range?.min;
    const max = range?.max;

    if (
      isEmptyValue(min) ||
      isEmptyValue(max) ||
      value === undefined ||
      isEmptyValue(value)
    ) {
      return stepConditionals._compareConditionalTernaryStates(
        conditional,
        CONDITIONAL_TERNARY_STATE.FAIL_NO_DATA
      );
    }
    const rangeToEvaluate = {
      min: typeof min === 'string' ? parseFloat(min) : min,
      max: typeof max === 'string' ? parseFloat(max) : max,
      include_min: Boolean(range?.include_min),
      include_max: Boolean(range?.include_max),
    };
    const valueNumber = typeof value === 'string' ? parseFloat(value) : value;
    const rangeLocation = evaluateRangeLocation(valueNumber, rangeToEvaluate);
    if (rangeLocation === RangeLocation.WithinRange) {
      return stepConditionals._compareConditionalTernaryStates(
        conditional,
        CONDITIONAL_TERNARY_STATE.PASS
      );
    }
    if (rangeLocation === RangeLocation.AboveRange) {
      return stepConditionals._compareConditionalTernaryStates(
        conditional,
        CONDITIONAL_TERNARY_STATE.FAIL_HIGH
      );
    }
    if (rangeLocation === RangeLocation.BelowRange) {
      return stepConditionals._compareConditionalTernaryStates(
        conditional,
        CONDITIONAL_TERNARY_STATE.FAIL_LOW
      );
    }

    return false;
  },

  _isNumberInputConditionalFulfilled: (
    fieldInputBlock: FieldInputBlock,
    conditional:
      | ContentBinaryConditionalDiffElement
      | ContentTernaryConditionalDiffElement
  ): boolean => {
    if (fieldInputBlock.inputType === 'number') {
      if (conditional.source_type === CONDITIONAL_TYPE.CONTENT_TERNARY) {
        if (fieldInputBlock.rule?.op !== 'range') return false;
        return stepConditionals._isTernaryConditionalFulfilled(
          fieldInputBlock,
          conditional as ContentTernaryConditionalDiffElement
        );
      }

      const state = stepConditionals._isNumberBlockFulfilled(fieldInputBlock)
        ? CONDITIONAL_STATE.PASS
        : CONDITIONAL_STATE.FAIL;
      return state === conditional.state;
    }

    return false;
  },

  _isTelemetryConditionalFulfilled: (
    telemetryBlock: RunTelemetryBlock,
    conditional:
      | ContentBinaryConditionalDiffElement
      | ContentTernaryConditionalDiffElement
  ): boolean => {
    if (
      telemetryBlock.rule === 'range' &&
      conditional.source_type === CONDITIONAL_TYPE.CONTENT_TERNARY
    ) {
      return stepConditionals._isTernaryConditionalFulfilled(
        telemetryBlock,
        conditional as ContentTernaryConditional
      );
    }
    const state = telemetryBlock.recorded?.pass
      ? CONDITIONAL_STATE.PASS
      : CONDITIONAL_STATE.FAIL;
    return state === conditional.state;
  },

  /**
   * Checks if a given conditional is satisifed by the current step. Step can
   * be from a procedure or a run, and the `recorded` values will be checked.
   *
   * @param step - Step to evaluate for conditional.
   * @param conditional - A step conditional.
   * @returns True if the conditional is fulfilled.
   */
  isConditionalFulfilled: (
    step: Step,
    conditional: ConditionalDiffElement
  ): boolean => {
    const state = getStepState(step);
    if (conditional.source_type === CONDITIONAL_TYPE.STEP) {
      // Skipping satisfies the conditional
      if (state === STEP_STATE.SKIPPED) {
        return true;
      }
      if (conditional.state === STEP_STATE.FAILED) {
        return state === STEP_STATE.FAILED;
      } else if (conditional.state === STEP_STATE.COMPLETED) {
        return state === STEP_STATE.COMPLETED;
      }
    } else if (conditional.source_type === CONDITIONAL_TYPE.CONTENT) {
      const block = (step.content as Array<StepBlock>).find(
        (block) => block.id === conditional.content_id
      );
      if (!block || block.type !== 'input' || state !== STEP_STATE.COMPLETED) {
        return false;
      }
      const fieldInput = block as FieldInputBlock;
      if (fieldInput.inputType === 'select') {
        return (
          (fieldInput as RunFieldInputCustomListBlock).recorded?.value ===
          conditional.state
        );
      } else if (fieldInput.inputType === 'list') {
        return (
          (fieldInput as RunFieldInputSettingsListBlock).recorded?.value ===
          conditional.state
        );
      } else if (fieldInput.inputType === 'multiple_choice') {
        return (
          (fieldInput as RunFieldInputMultipleChoiceBlock).recorded?.value ===
          conditional.state
        );
      }
      // Unknown, default to safest and don't proceed.
      return false;
    } else if (
      conditional.source_type === CONDITIONAL_TYPE.CONTENT_BINARY ||
      conditional.source_type === CONDITIONAL_TYPE.CONTENT_TERNARY
    ) {
      const block = (step.content as Array<StepBlock>).find(
        (block) => block.id === conditional.content_id
      );
      if (!block || state !== STEP_STATE.COMPLETED) {
        return false;
      }
      if (block.type === 'input') {
        return stepConditionals._isNumberInputConditionalFulfilled(
          block,
          conditional
        );
      }
      if (block.type === 'telemetry') {
        return stepConditionals._isTelemetryConditionalFulfilled(
          block,
          conditional
        );
      }
    }
    return false;
  },

  areConditionalsRedundant: (conditionals: Conditional[]): boolean => {
    if (!conditionals || conditionals.length < 2) {
      return false;
    }
    return conditionals.every((conditional, _, arr) => {
      return (
        conditional.source_id === arr[0].source_id &&
        conditional.target_id === arr[0].target_id
      );
    });
  },

  isSingleConditional: (conditionals: Conditional[]): boolean => {
    if (!conditionals) {
      return false;
    }
    return (
      conditionals.length === 1 &&
      conditionals[0].source_type === CONDITIONAL_TYPE.STEP
    );
  },

  getInitialConditionals: (
    stepId: string,
    sourceType: SourceType,
    contentId: string | null,
    states: Array<StepConditionalState | string>
  ): Array<Conditional> => {
    return states.map(
      (state) =>
        ({
          id: generateStepConditionalId(),
          source_type: sourceType,
          source_id: stepId,
          content_id: contentId,
          state,
          target_type: 'step',
          target_id: '',
        } as Conditional)
    );
  },

  _getConditionalType: (conditionals: Conditional[]): SourceType | null => {
    if (conditionals && conditionals.length > 0) {
      return conditionals[0].source_type;
    }
    return null;
  },

  /**
   * Merge initial conditionals into existing conditionals.  The existing conditionals
   * will be updated to only include the conditional states present in the initial conditionals.
   * The values of existing conditionals will be preserved if they already exist,
   * othewise the values from the initial conditionals will be used.
   *
   * @param step - The step with existing conditionals
   * @param initials - Conditional array that represents initial conditionals
   */
  mergeInitialConditionals: (step: Step, initials: Conditional[]): void => {
    if (!step.conditionals) {
      return;
    }
    if (
      stepConditionals._getConditionalType(step.conditionals) !==
      stepConditionals._getConditionalType(initials)
    ) {
      return;
    }
    const conditionalsMap: { [state: string]: StepConditional } = Object.assign(
      {},
      ...step.conditionals.map((conditional) => ({
        [conditional.state]: conditional,
      }))
    );
    const conditionals: Conditional[] = [];
    for (const conditional of initials) {
      if (conditionalsMap[conditional.state]) {
        conditionals.push(conditionalsMap[conditional.state]);
      } else {
        conditionals.push(conditional);
      }
    }
    step.conditionals = conditionals;
  },

  /**
   * Gets the content block index assocated with the step conditional
   *
   * @param step - The step with conditional
   * @returns Index of the content block associated with the conditional
   */
  _getContentIndexOfConditional: (step: Step): number | null => {
    if (!(step.conditionals && step.conditionals.length > 0)) {
      return null;
    }
    // Currently, only one content block can be assigned a conditional per step
    const contentId = step.conditionals[0].content_id;
    if (!contentId) {
      return null;
    }

    const blockIndex = step.content.findIndex(
      (content) => content.id === contentId
    );
    if (blockIndex === -1) {
      return null;
    }
    return blockIndex;
  },

  /**
   * Gets the existing recorded value associated with a step conditional
   *
   * @param step - The step with conditional
   * @param contentIndex - Index of the content block associated with the conditional
   * @returns Recorded value associated with a conditional, if present.  Otherwise,
   * null is returned if the record is not present
   */
  _getExistingRecordedConditional: (
    step: Step,
    contentIndex: number
  ): ConditionalValue | null => {
    const fieldInput = step.content[contentIndex] as FieldInputBlock;
    if (!['list', 'select', 'multiple_choice'].includes(fieldInput.inputType)) {
      return null;
    }
    return (
      (fieldInput as RunFieldInputConditionalBlock).recorded?.value ?? null
    );
  },

  /**
   * Gets the recorded value associated with a step conditional
   *
   * @param recordedValues - A map of content indices to recorded content
   * @param step - The step associated with the recorded values
   * @returns Recorded value associated with a conditional, if present.  Otherwise,
   * null is returned if the record is not found or conditionals are not present
   */
  getRecordedConditionalValue: (
    recordedValues: RecordedValues,
    step: Step
  ): ConditionalValue | null => {
    const contentIndex = stepConditionals._getContentIndexOfConditional(step);
    if (contentIndex === null) {
      return null;
    }
    if (
      recordedValues &&
      recordedValues[contentIndex] &&
      recordedValues[contentIndex].value
    ) {
      return recordedValues[contentIndex].value;
    }
    return stepConditionals._getExistingRecordedConditional(step, contentIndex);
  },

  /**
   * Gets the target step Id of a step with completed conditional, if it exists
   *
   * @param step - The step with a conditional
   * @returns Step Id of the target step, based on the conditional value.  If none exists,
   * null is returned
   */
  getFulfilledTargetId: (step: Step): string | null => {
    if (!(step.conditionals && step.conditionals.length > 0)) {
      return null;
    }
    const state = getStepState(step);
    const conditional = step.conditionals[0];
    if (conditional.source_type === CONDITIONAL_TYPE.STEP) {
      // Skipping satisfies the "step type" conditional
      if (state === STEP_STATE.SKIPPED || state === STEP_STATE.COMPLETED) {
        return (
          step.conditionals.find(
            (conditional) => conditional.state === STEP_STATE.COMPLETED
          )?.target_id || null
        );
      } else if (state === STEP_STATE.FAILED) {
        return (
          step.conditionals.find(
            (conditional) => conditional.state === STEP_STATE.FAILED
          )?.target_id || null
        );
      }
      return null;
    } else if (stepConditionals.isContentConditional(conditional)) {
      if (state !== STEP_STATE.COMPLETED) {
        return null;
      }
      if (step.actions && step.actions.length > 0) {
        const action = [...step.actions]
          .reverse()
          .find((action) =>
            ['complete', 'signoff', 'fail'].includes(action.type)
          );

        const conditionalValue = (action as StepSignoffAction | StepEndAction)
          ?.conditional_value;
        return (
          step.conditionals.find(
            (conditional) => conditional.state === conditionalValue
          )?.target_id || null
        );
      }
      return null;
    }
    return null;
  },

  /**
   * Gets a map of step id to target step id. If a version is provided, the map for that version is returned.
   */
  getIdsToLabelsMapForVersion: (
    sections: Array<ReleaseSection | DraftSection | SectionDiffElement>,
    style: 'letters' | 'numbers',
    version: 'old' | 'new' = 'new'
  ): { [stepId: string]: string } => {
    const labelsMap = {};

    const sectionIdMap = diffUtil.getContainerMap(sections, version);
    const sectionsForVersion = sections.filter((section) => {
      const sectionId = diffUtil.getDiffValue<string>(section, 'id', version);
      return sectionIdMap.has(sectionId);
    });
    sectionsForVersion.forEach((section, sectionIndex) => {
      const sectionId = diffUtil.getDiffValue<string>(section, 'id', version);
      labelsMap[sectionId] = runUtil.displaySectionKey(
        sectionsForVersion,
        sectionIndex,
        style
      );
      const steps = section.steps as Array<
        ReleaseStep | DraftStep | StepDiffElement
      >;
      const stepIdMap = diffUtil.getContainerMap(steps, version);
      const stepsForVersion = steps.filter((step) => {
        const stepId = diffUtil.getDiffValue<string>(step, 'id', version);
        return stepIdMap.has(stepId);
      });
      stepsForVersion.forEach((step, stepIndex) => {
        const stepId = diffUtil.getDiffValue<string>(step, 'id', version);
        labelsMap[stepId] = runUtil.displaySectionStepKey(
          sectionsForVersion,
          sectionIndex,
          stepIndex,
          style
        );
      });
    });

    return labelsMap;
  },

  getDiffChangeStateFromSourceConditionalMaps: (
    step: StepDiffElement,
    sourceStepConditionalsMap: SourceConditionalsMap,
    removedSourceStepConditionalsMap?: SourceConditionalsMap
  ): DiffArrayChangeSymbol => {
    const stepId = diffUtil.getDiffValue<string>(step, 'id', 'new');
    const conditionals = sourceStepConditionalsMap?.[stepId]
      ? [...Object.values(sourceStepConditionalsMap[stepId])]
      : [];
    const oldConditionals =
      removedSourceStepConditionalsMap &&
      removedSourceStepConditionalsMap?.[stepId]
        ? [...Object.values(removedSourceStepConditionalsMap[stepId])]
        : [];
    return stepConditionals.getDiffChangeStateForConditionals(stepId, [
      ...conditionals,
      ...oldConditionals,
    ]);
  },

  getDiffChangeStateForConditionals: (
    stepId: string,
    conditionals: Array<ConditionalDiffElement>
  ): DiffArrayChangeSymbol => {
    if (
      conditionals.length > 0 &&
      (conditionals.every(
        (conditional) =>
          conditional.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED
      ) ||
        conditionals.every(
          (conditional) =>
            diffUtil.isChanged(conditional, 'target_id') &&
            diffUtil.getDiffValue(conditional, 'target_id', 'new') === stepId
        ))
    ) {
      return ARRAY_CHANGE_SYMBOLS.ADDED;
    }
    if (
      conditionals.length > 0 &&
      conditionals.every(
        (conditional) =>
          conditional.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED ||
          conditionals.every(
            (conditional) =>
              diffUtil.isChanged(conditional, 'target_id') &&
              diffUtil.getDiffValue(conditional, 'target_id', 'old') === stepId
          )
      )
    ) {
      return ARRAY_CHANGE_SYMBOLS.REMOVED;
    }
    if (
      conditionals.length === 0 ||
      (conditionals.length > 0 &&
        conditionals.every(
          (conditional) =>
            !conditional.diff_change_state ||
            conditional.diff_change_state === ARRAY_CHANGE_SYMBOLS.UNCHANGED
        ))
    ) {
      return ARRAY_CHANGE_SYMBOLS.UNCHANGED;
    }
    return ARRAY_CHANGE_SYMBOLS.MODIFIED;
  },

  checkStepHasNewerDependencyCompletion: (
    step: RunStep,
    graph: ProcedureGraph
  ): boolean => {
    const memo = new Map<string, boolean>();
    const seen = new Set<string>();

    const recurseDependency = (stepId: string, timestamp?: string) => {
      const memoResult = memo.get(stepId);
      if (memoResult !== undefined) {
        return memoResult;
      }
      if (seen.has(stepId)) {
        return false;
      }
      seen.add(stepId);
      const step = graph.stepMap.get(stepId) as RunStep;
      if (
        step &&
        step.conditionals &&
        step.completedAt &&
        timestamp &&
        step.completedAt > timestamp
      ) {
        memo.set(stepId, true);
        return true;
      } else if (graph.incoming.has(stepId)) {
        let dependencyIsLater = false;
        graph.incoming.get(stepId)?.forEach((_, dependency) => {
          dependencyIsLater =
            dependencyIsLater || recurseDependency(dependency, timestamp);
        });
        memo.set(stepId, dependencyIsLater);
        return dependencyIsLater;
      }
      memo.set(stepId, false);
      return false;
    };
    return recurseDependency(step.id, step.completedAt);
  },

  checkConditionalsFulfilled: ({
    run,
    step,
    runGraph,
  }: {
    run?: Run;
    step: Step;
    runGraph?: ProcedureGraph;
  }) => {
    if (!run && !runGraph) {
      throw new Error('Either run or runGraph must be provided');
    }
    let dependencyGraph;
    if (runGraph) {
      dependencyGraph = runGraph;
    }
    if (run) {
      dependencyGraph = new ProcedureGraph(run);
    }
    const conditionals: Record<string, Conditional> =
      dependencyGraph.sourceStepConditionalsMap[step.id];
    if (!conditionals) {
      return true;
    }

    for (const conditional of Object.values(conditionals)) {
      const sourceStep: RunStep = dependencyGraph.stepMap.get(
        conditional.source_id
      );
      if (
        sourceStep &&
        stepConditionals.isConditionalFulfilled(sourceStep, conditional)
      ) {
        return true;
      }
    }
    return false;
  },

  isContentConditional: (
    conditional: Conditional | StepConditionalDiffElement
  ): conditional is ContentBinaryConditional | ContentTernaryConditional =>
    conditional.source_type === CONDITIONAL_TYPE.CONTENT ||
    conditional.source_type === CONDITIONAL_TYPE.CONTENT_BINARY ||
    conditional.source_type === CONDITIONAL_TYPE.CONTENT_TERNARY,
};

export default stepConditionals;
