import { isNil } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import labels from 'shared/lib/labelUtil';
import { ACTION_TYPE, isStepEnded, shouldRecordExpressionForBlock } from 'shared/lib/runUtil';
import { SimulatedFields, extractParameterNamesFromExpression } from 'shared/lib/telemetry';
import { TelemetryParams } from 'shared/lib/types/telemetry';
import {
  ParentReferenceType,
  RunExpressionBlock,
  RunFieldInputTableBlock,
  RunStepBlock,
  RunStepBlockWithRecorded,
  RunTelemetryBlock,
  RunTextBlock,
  Run as RunType,
  Section,
} from 'shared/lib/types/views/procedures';
import '../App.css';
import RunService from '../api/runs';
import { selectOfflineInfo } from '../app/offline';
import { BlockTypes } from '../components/Blocks/BlockTypes';
import EndOfRunContent from '../components/EndOfRunContent';
import { DEFAULT_VIEW_MODE_DEFAULT, VIEW_MODES } from '../components/FieldSetViewModeEditSelect';
import FirstStepButton from '../components/FirstStepButton';
import NotFound from '../components/NotFound';
import OperationSelector from '../components/Operations/OperationSelector';
import PauseModal from '../components/PauseModal';
import PausedStickyHeader from '../components/PausedStickyHeader';
import ProcedureHeader from '../components/ProcedureHeader';
import ProcedureSection from '../components/ProcedureSection';
import RunDescription from '../components/RunDescription';
import RunFilter from '../components/RunFilter';
import RunProcedureVariables from '../components/RunProcedureVariables';
import RunSidebar from '../components/RunSidebar';
import RunStickyHeader from '../components/RunStickyHeader';
import { useAuth } from '../contexts/AuthContext';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import { ProcedureContextProvider } from '../contexts/ProcedureContext';
import { INTRO_STEP_KEY, RunContextProvider } from '../contexts/RunContext';
import { RunFilterProvider } from '../contexts/RunFilterContext';
import {
  API_WEBHOOK_URL_KEY,
  REQUIRE_SUGGESTED_EDIT_APPROVAL_KEY,
  STREAM_TELELMETRY_FOR_COLLAPSED_STEPS,
  useSettings,
} from '../contexts/SettingsContext';
import { useUserInfo } from '../contexts/UserContext';
import { selectRunStep } from '../contexts/runsSlice';
import { saveRunTags } from '../contexts/settingsSlice';
import SidebarLayout from '../elements/SidebarLayout';
import useExpandCollapse from '../hooks/useExpandCollapse';
import { useExpressionHelper } from '../hooks/useExpression';
import useLocationParams from '../hooks/useLocationParams';
import usePrintHeader from '../hooks/usePrintHeader';
import useRun from '../hooks/useRun';
import useRunActions from '../hooks/useRunActions';
import useRunObserver from '../hooks/useRunObserver';
import useRunPreview from '../hooks/useRunPreview';
import useTelemetryParameters from '../hooks/useTelemetryParameters';
import useUnits from '../hooks/useUnits';
import useUrlScrollTo from '../hooks/useUrlScrollTo';
import apm from '../lib/apm';
import { PERM } from '../lib/auth';
import configUtil from '../lib/configUtil';
import {
  issuePath,
  operationViewPath,
  procedureEditPath,
  procedureReviewPath,
  runViewPath,
  runsPath,
  testingPlansPath,
} from '../lib/pathUtil';
import printUtil from '../lib/printUtil';
import procedureUtil from '../lib/procedureUtil';
import runUtil, { PARTICIPANT_TYPE, RUN_STATE } from '../lib/runUtil';
import { getScrollToUrlParams } from '../lib/scrollToUtil';
import { createNewTag } from '../lib/tagsUtil';
import telemetryUtil from '../lib/telemetry';
import PartList from '../manufacturing/components/PartList';
import Tags from '../manufacturing/components/Tags';
import usePartBlockHelpers from '../manufacturing/hooks/usePartBlockHelpers';
import SnippetSelector from '../testing/components/SnippetSelector';
import RunLabel from '../components/RunLabel';

const SECTION_COLLAPSE_THRESHOLD = 10;
const newValuesRecordedSection: (section: Section) => { steps: Array<{ recorded }> } = (section) => ({
  steps: section.steps.map(() => ({ recorded: {} })),
});

const newValuesRecorded: (run: RunType | null) => { sections: Array<{ steps: Array<{ recorded }> }> } | null = (
  run
) => {
  if (!run) {
    return null;
  }
  const valuesRecorded: { sections: Array<{ steps: Array<{ recorded }> }> } = { sections: [] };
  run.sections.forEach((section, sectionIndex) => {
    valuesRecorded.sections[sectionIndex] = newValuesRecordedSection(section);
  });
  return valuesRecorded;
};

export enum PREVIEW_MODE {
  NONE = 'none',
  EDIT = 'edit',
  REVIEW = 'review',
}

const Run = ({ previewMode = PREVIEW_MODE.NONE }) => {
  // Get the `online` property only in order to prevent unnecessary rerenders.
  const online = useSelector((state) => selectOfflineInfo(state).online);

  const isPreviewMode = useMemo(() => previewMode !== PREVIEW_MODE.NONE, [previewMode]);

  const { id, run, runNotFound, updatePreviewRun } = useRunPreview(isPreviewMode);
  const {
    addLinkedRun,
    addStepComment,
    editStepComment,
    addStepAfter,
    completeStep,
    endRun,
    signOffStep,
    pinSignOffStep,
    revokeStepSignoff,
    skipStep,
    skipSection,
    repeatStep,
    repeatSection,
    startRun,
    addParticipant,
    removeParticipant,
    setOperation,
    clearOperation,
    updateRunTags,
    failStep,
    saveRedlineBlock,
    saveRedlineStepField,
    saveRedlineStepComment,
    updateBlock,
    saveVariable,
    saveRedlineHeader,
    updateStepDetails,
  } = useRunActions(isPreviewMode, updatePreviewRun);
  const { id: paramId } = useParams<{ id: string }>();
  const history = useHistory<{ operation?: string } | { goToTab: 'list' | 'chart' }>();
  const location = useLocation();
  const store = useStore();
  const { searchParams } = useLocationParams(location);
  const { fetchedTelemetryParameters } = useTelemetryParameters({ procedure: run });
  const { configurePartKitBlock, configurePartBuildBlock } = usePartBlockHelpers({ part: run?.part_list?.part });
  const { findDefinedUnit } = useUnits();

  const [redlineState, setRedlineState] = useState({
    runs: null,
    enabled: false,
    loading: true,
  });

  const [showStepAction, setShowStepAction] = useState<null | string>(null);
  const [currentStepId, setCurrentStepId] = useState(null);
  const [participantStateLoading, setParticipantStateLoading] = useState(true);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const valuesRecordedRef = useRef<{ sections: Array<{ steps: Array<{ recorded }> }> } | null>(null);
  const { services, currentTeamId } = useDatabaseServices();
  const { userInfo } = useUserInfo();
  const { auth } = useAuth();
  const { mixpanel } = useMixpanel();
  const [shouldCollapseAll, setShouldCollapseAll] = useState(true); // Load run with all sections and headers collapsed the first time for performance.
  const [filterSelectedOperators, setFilterSelectedOperators] = useState<Array<string>>([]);
  const dispatch = useDispatch();
  const isMounted = useRef(true);

  const { displaySections } = useRun({ run });
  const { run: sourceRun } = useRunObserver({ id: run && run.source_run });
  const { config, getSetting } = useSettings();
  const { runTags } = useSettings();
  const { getExpressionResult } = useExpressionHelper({
    displaySectionAs: getSetting('display_sections_as', 'letters'),
    currentTeamId,
    run,
    isRun: runUtil.isRun(run),
    isPreviewMode,
  });
  const showRunPausedStickyHeader = useMemo(() => run && run.state === RUN_STATE.PAUSED, [run]);

  const showAutomationPauseStickyHeader = useMemo(() => run && run.automation_status === RUN_STATE.PAUSED, [run]);
  const stickyHeaderHeightRem = 2.5 * (showRunPausedStickyHeader || showAutomationPauseStickyHeader ? 2 : 1);

  const [viewMode, setViewMode] = useState<string | null>(null);

  const runHeader = useMemo(() => {
    return run ? `Run · ${run.name}${run.state === RUN_STATE.PAUSED ? ' (PAUSED)' : ''}` : 'Run';
  }, [run]);

  const printHeader = useMemo(() => {
    return printUtil.getPrintHeader(run, runHeader);
  }, [run, runHeader]);

  usePrintHeader(runHeader, printHeader);

  const printFooter = useMemo(() => {
    return printUtil.getPrintFooter(run);
  }, [run]);

  useEffect(() => {
    if (run && viewMode === null) {
      if (run.default_view_mode) {
        setViewMode(run.default_view_mode);
      } else {
        setViewMode(DEFAULT_VIEW_MODE_DEFAULT);
      }
    }
  }, [run, viewMode]);

  // Clear operator filters any time the view mode changes
  useEffect(() => {
    setFilterSelectedOperators([]);
  }, [viewMode]);

  const {
    isCollapsedMap,
    setAllExpanded,
    setIsCollapsed,
    areAllStepsInSectionExpanded,
    setAllStepsInSectionExpanded,
    areRedlineCommentsExpanded,
    expandRedlineComments,
  } = useExpandCollapse();

  // Array of booleans mapping whether all the steps in section *i* are collapsed
  const allStepsInSectionExpandedMap = useMemo(
    () => run && run.sections.map(areAllStepsInSectionExpanded),
    [areAllStepsInSectionExpanded, run]
  );

  const sectionIds = React.useMemo(() => (run && run.sections ? run.sections.map((section) => section.id) : []), [run]);
  const headerIds = React.useMemo(() => (run && run.headers ? run.headers.map((header) => header.id) : []), [run]);

  const collapseAllHeadersAndSections = useCallback(() => {
    setAllExpanded(false, sectionIds, headerIds);
  }, [setAllExpanded, sectionIds, headerIds]);

  const expandAllHeadersAndSections = useCallback(() => {
    setAllExpanded(true, sectionIds, headerIds);
  }, [setAllExpanded, sectionIds, headerIds]);

  const userId = useMemo(() => userInfo.session.user_id, [userInfo.session.user_id]);

  // We collapse the sections on first render when the run has SECTION_COLLAPSE_THRESHOLD or more sections.
  useEffect(() => {
    // Wait until run document has loaded.
    if (!run) {
      return;
    }
    if (run.sections.length >= SECTION_COLLAPSE_THRESHOLD && shouldCollapseAll) {
      collapseAllHeadersAndSections();
    }
    /**
     * Mark initial collapsing complete, whether or not we've actually collapsed.
     * This prevents us from collapsing sections unexpectedly as the user repeats
     * sections.
     */
    setShouldCollapseAll(false);
  }, [run, collapseAllHeadersAndSections, shouldCollapseAll]);

  // Update valuesRecordedRef immediately after the run changes to keep the ref in sync with the run.
  useLayoutEffect(() => {
    valuesRecordedRef.current = newValuesRecorded(run);
  }, [run]);

  // Flag for detecting when component is unmounted.
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  useEffect(() => {
    if (!run) {
      return;
    }
    if (!runUtil.isRunStateActive(run.state)) {
      setParticipantStateLoading(false);
      return;
    }
    if (runUtil.getIsUserParticipantOrViewing(run, userId)) {
      setParticipantStateLoading(false);
    }
  }, [run, userId]);

  // Set participant state the first time a user enters a run
  useEffect(() => {
    if (!run || !config || !participantStateLoading || !runUtil.isRunStateActive(run.state)) {
      return;
    }

    // User has already entered this run, no need to continue
    if (runUtil.getIsUserParticipantOrViewing(run, userId)) {
      return;
    }

    const setUserAsParticipant = () => {
      addParticipant(currentTeamId, run, userId);
    };

    const setUserAsViewing = () => {
      removeParticipant(currentTeamId, run, userId);
    };

    configUtil.getUserParticipantType(config) === 'participant' ? setUserAsParticipant() : setUserAsViewing();
  }, [config, currentTeamId, participantStateLoading, run, addParticipant, removeParticipant, userId]);

  const parentReference = useMemo(() => {
    if (!run) {
      return null;
    }
    if (run.source_run && sourceRun) {
      return {
        id: run.source_run,
        type: ParentReferenceType.Run,
      };
    }
    if (run.parent_reference) {
      return run.parent_reference;
    }
    return null;
  }, [run, sourceRun]);

  const loading = useMemo(
    () =>
      /*
       * Loading if run is not loaded, or redline doc is loading (if needed),
       * or participant state is loading
       */
      !run || redlineState.loading || participantStateLoading,
    [run, redlineState.loading, participantStateLoading]
  );

  const sourceRunStepId = useMemo(() => {
    if (!sourceRun || !id) {
      return;
    }
    return runUtil.linkedRunStepId(sourceRun, id);
  }, [id, sourceRun]);

  /**
   * Get link back to parent linked procedure.
   *
   * If this is a linked procedure, attempt to return to the step that
   * links to this procedure when returning to the source/parent run.
   * Run.js auto-scrolls to the given step or section when present
   * in the hash portion of the url.
   */
  const sourceRunLink = useMemo(() => {
    if (!run || !run.source_run) {
      return '';
    }
    return `${runViewPath(currentTeamId, run.source_run)}${
      sourceRunStepId ? getScrollToUrlParams({ id: sourceRunStepId, type: 'step' }) : ''
    }`;
  }, [run, sourceRunStepId, currentTeamId]);

  const sourceLink = useMemo(() => {
    if (!parentReference) {
      return '';
    }
    if (parentReference.type === 'run') {
      return sourceRunLink;
    }
    return issuePath(currentTeamId, parentReference.id.toString());
  }, [parentReference, sourceRunLink, currentTeamId]);

  const sourceOperationLink = useMemo(() => {
    if (!history.location.state?.['operation']) {
      return '';
    }
    return `${operationViewPath(currentTeamId, history.location.state['operation'])}`;
  }, [currentTeamId, history.location.state]);

  const participantUserIds = useMemo(() => {
    if (!run || !run.participants) {
      return [];
    }
    return run.participants
      ? run.participants
          .filter((participant) => participant.type === PARTICIPANT_TYPE.PARTICIPATING)
          .map((participant) => participant.user_id)
      : [];
  }, [run]);

  const pauseRun = useCallback(
    (comment) => {
      if (!services.runs || !runUtil.isRunStateActive(run?.state)) {
        return;
      }
      if (mixpanel) {
        mixpanel.track('Run Paused');
      }
      return services.runs.insertAction(run, ACTION_TYPE.PAUSE, comment).catch(() => {
        // ignore
      });
    },
    [mixpanel, run, services.runs]
  );

  const resumeRun = useCallback(() => {
    if (!services.runs || !runUtil.isRunStateActive(run?.state)) {
      return;
    }
    if (mixpanel) {
      mixpanel.track('Run Resumed');
    }
    return services.runs.insertAction(run, ACTION_TYPE.RESUME).catch(() => {
      // ignore
    });
  }, [mixpanel, run, services.runs]);

  const resumeAutomation = useCallback(
    (stepId = '') => {
      if (!services.runs) {
        return;
      }

      if (mixpanel) {
        mixpanel.track('Automation Resumed');
      }

      return services.runs.startAutomation(run?._id, true, stepId);
    },
    [mixpanel, run, services.runs]
  );

  // Handle direct url scroll to section/step
  const { onScrollToRefChanged, onScrollToId } = useUrlScrollTo({
    setIsCollapsed,
    expandRedlineComments,
    setShowStepAction,
    procedure: run,
    searchParams,
    viewMode,
  });

  // Will showStep section/step/stepHeader/content/header with associated id and expand that section and/or step or header.
  const showStep = useCallback(
    ({ headerId, sectionId, stepId, stepHeaderId, contentId, commentId }) => {
      const scrollToId = headerId || contentId || stepHeaderId || stepId || sectionId;
      onScrollToId({
        headerId,
        sectionId,
        stepId,
        commentId,
        scrollToId,
      });
    },
    [onScrollToId]
  );

  const isRunInputEnabled = useMemo(() => {
    if (!run || run.state !== RUN_STATE.RUNNING) {
      return false;
    }
    if (!auth.hasPermission(PERM.RUNS_EDIT, run.project_id)) {
      return false;
    }
    if (!runUtil.getIsUserParticipant(run, userId)) {
      return false;
    }
    return true;
  }, [run, auth, userId]);

  const canChangeOperation = useMemo(() => {
    if (!run) {
      return false;
    }
    if (run.state === 'completed') {
      return online && auth.hasPermission(PERM.CHANGE_OPERATION);
    }
    return (
      auth.hasPermission(PERM.CHANGE_OPERATION) ||
      (runUtil.getIsUserParticipant(run, userId) && auth.hasPermission(PERM.RUNS_EDIT))
    );
  }, [auth, online, run, userId]);

  const canClearOperation = useMemo(() => {
    if (!run) {
      return false;
    }
    if (run.state === 'completed') {
      return false;
    }
    return (
      auth.hasPermission(PERM.CHANGE_OPERATION) ||
      (runUtil.getIsUserParticipant(run, userId) && auth.hasPermission(PERM.RUNS_EDIT))
    );
  }, [auth, run, userId]);

  const onStartLinkedRun = useCallback(
    (sectionIndex, stepIndex, contentIndex, linkedRun) => {
      if (!run) {
        return;
      }
      const sectionId = run.sections[sectionIndex].id;
      const stepId = run.sections[sectionIndex].steps[stepIndex].id;
      const contentId = run.sections[sectionIndex].steps[stepIndex].content[contentIndex].id;
      // Sanity check content id.
      if (!contentId) {
        return;
      }
      // Start linked run.
      startRun(currentTeamId, linkedRun);
      // Add linked run to parent run.
      addLinkedRun(currentTeamId, run, sectionId, stepId, contentId, linkedRun._id);
    },
    [run, currentTeamId, startRun, addLinkedRun]
  );

  const onSaveOperationTag = (operation) => {
    if (operation.indexOf('%') !== -1) {
      return;
    }
    operation = {
      key: labels.getLabelKey(operation),
      name: operation.trim(),
    };

    // Queue addition of the operation in the runsSlice
    setOperation(currentTeamId, run, operation);
  };

  const onClearOperationTag = () => {
    // Queue deletion of the operation in the runsSlice
    clearOperation(currentTeamId, run);
  };

  const runTagOptions = useMemo(() => {
    if (runTags && runTags.run_tags) {
      return Object.values(runTags.run_tags).map((tag) => {
        return {
          ...tag,
          id: tag.key,
        };
      });
    }
    return [];
  }, [runTags]);

  const onSaveTag = useCallback(
    (tagId): Promise<void> => {
      if (!run) {
        return Promise.resolve();
      }

      let tagObject = runTagOptions.find((tag) => tagId === tag.id);

      // If its a new tag, save it in the global tags list.
      if (!tagObject) {
        tagObject = { id: tagId, ...createNewTag(tagId) };
      }

      const runTag = { key: tagObject.key, name: tagObject.name };
      const newSelectedTags = run.run_tags ? [...run.run_tags, runTag] : [runTag];

      if (!isPreviewMode) {
        dispatch(saveRunTags(newSelectedTags, currentTeamId));
      }

      // Queue addition of the run tags in the runsSlice
      updateRunTags(currentTeamId, run, newSelectedTags);

      return Promise.resolve();
    },
    [runTagOptions, run, currentTeamId, isPreviewMode, dispatch, updateRunTags]
  );

  const onRemoveTag = useCallback(
    (removedTag): Promise<void> => {
      if (!run || !run.run_tags) {
        return Promise.resolve();
      }

      const updatedRunTags = run.run_tags.filter((tag) => tag.key !== removedTag.id);

      if (!isPreviewMode) {
        dispatch(saveRunTags(updatedRunTags, currentTeamId));
      }

      // Queue addition of the run tags in the runsSlice
      updateRunTags(currentTeamId, run, updatedRunTags);

      return Promise.resolve();
    },
    [run, currentTeamId, dispatch, isPreviewMode, updateRunTags]
  );

  const procedureTags = useMemo(() => {
    if (!run || !run.tags?.length) {
      return [];
    }
    return run.tags.map((tag) => {
      return {
        ...tag,
        id: tag.key,
      };
    });
  }, [run]);

  const selectedTags = useMemo(() => {
    if (!run || !run.run_tags?.length) {
      return [];
    }

    return run.run_tags.map((tag) => {
      return {
        ...tag,
        id: tag.key,
      };
    });
  }, [run]);

  const isTagsSelectorDisabled = useMemo(() => {
    if (!run) {
      return true;
    }
    if (run.state === 'completed') {
      return !online || !auth.hasPermission(PERM.RUNS_EDIT, run.project_id);
    }
    return !auth.hasPermission(PERM.RUNS_EDIT, run.project_id);
  }, [auth, online, run]);

  const onRepeatStep = (sectionIndex, stepIndex, recorded) => {
    if (!run) {
      return;
    }
    const recordedTelemetry = getRecordedTelemetryStep(sectionIndex, stepIndex);
    const sectionId = run.sections[sectionIndex].id;
    const stepId = run.sections[sectionIndex].steps[stepIndex].id;

    // The step repeat must be created here so that a copy of the new step with the same ids will be used both offline and during sync
    const stepRepeat = RunService.createStepRepeat(run, userId, sectionId, stepId);

    const includeRedlines = !getSetting(REQUIRE_SUGGESTED_EDIT_APPROVAL_KEY, false);
    recorded = { ...recorded, ...recordedTelemetry };
    repeatStep(currentTeamId, run, userId, recorded, stepRepeat, sectionId, stepId, includeRedlines);
  };

  const onRepeatSection = (sectionIndex) => {
    if (!run) {
      return;
    }

    const sectionId = run.sections[sectionIndex].id;
    const recordedTelemetrySection = getRecordedTelemetrySection(sectionIndex);
    const recordedContent = getLiveContentSection(sectionIndex, recordedTelemetrySection);

    // The step repeat must be created here so that a copy of the new step with the same ids will be used both offline and during sync
    const sectionRepeatOptions = RunService.createSectionRepeat(run, userId, sectionId);

    const includeRedlines = !getSetting(REQUIRE_SUGGESTED_EDIT_APPROVAL_KEY, false);

    repeatSection(currentTeamId, run, userId, recordedContent, sectionRepeatOptions, sectionId, includeRedlines);
  };

  const onSkipStep = (sectionIndex, stepIndex, recorded) => {
    if (!run) {
      return;
    }
    const recordedTelemetry = getRecordedTelemetryStep(sectionIndex, stepIndex);

    recorded = { ...recorded, ...recordedTelemetry };
    const sectionId = run.sections[sectionIndex].id;
    const stepId = run.sections[sectionIndex].steps[stepIndex].id;
    skipStep(currentTeamId, run, userId, sectionId, stepId, recorded);
  };

  const onSkipSection = (sectionIndex) => {
    if (!run) {
      return;
    }
    const recordedTelemetrySection = getRecordedTelemetrySection(sectionIndex);
    const recordedContent = getLiveContentSection(sectionIndex, recordedTelemetrySection);
    const skippedAt = new Date().toISOString();
    const sectionId = run.sections[sectionIndex].id;
    skipSection(currentTeamId, run, userId, sectionId, skippedAt, recordedContent);
  };

  const onSaveRedlineBlock = (sectionIndex, stepIndex, contentIndex, block, isRedline) => {
    if (!run) {
      return;
    }
    const sectionId = run.sections[sectionIndex].id;
    const step = run.sections[sectionIndex].steps[stepIndex];
    const pending = !runUtil.canIncludeRedlines(step) || getSetting(REQUIRE_SUGGESTED_EDIT_APPROVAL_KEY, false);

    saveRedlineBlock(currentTeamId, run, userId, sectionId, step.id, contentIndex, block, pending, isRedline);
    return Promise.resolve();
  };

  const onSaveHeaderRedline = (header, headerRedlineMetadata, isRedline) => {
    if (!run) {
      return;
    }
    const pending = getSetting(REQUIRE_SUGGESTED_EDIT_APPROVAL_KEY, false);

    saveRedlineHeader(currentTeamId, run, userId, header, headerRedlineMetadata, pending, isRedline);
    return Promise.resolve();
  };

  const saveSectionHeaderRedline = (sectionHeader, sectionHeaderRedlineMetadata) => {
    if (!run) {
      return;
    }
    const createdAt = new Date();
    const pending = getSetting(REQUIRE_SUGGESTED_EDIT_APPROVAL_KEY, false);
    return services.runs
      .saveSectionHeaderRedline(run._id, createdAt, sectionHeader, pending, sectionHeaderRedlineMetadata)
      .catch(() => {
        // ignore
      });
  };

  const onSaveRedlineStepField = (stepId, stepField, isRedline) => {
    if (!run) {
      return;
    }
    const step = procedureUtil.getStepById(run, stepId);

    const pending = !runUtil.canIncludeRedlines(step) || getSetting(REQUIRE_SUGGESTED_EDIT_APPROVAL_KEY, false);

    saveRedlineStepField(currentTeamId, run, userId, stepId, stepField, pending, isRedline);
    return Promise.resolve();
  };

  const onSaveRedlineStepComment = (stepId, text, commentId) => {
    saveRedlineStepComment(currentTeamId, run, userId, stepId, text, commentId);
    return Promise.resolve();
  };

  const acceptPendingStepRedline = (stepId, redlineIndex) => {
    return services.runs.acceptPendingStepRedline(run, userId, stepId, redlineIndex);
  };

  const acceptPendingHeaderRedline = (headerId, redlineIndex) => {
    return services.runs.acceptPendingHeaderRedline(run, userId, headerId, redlineIndex);
  };

  const acceptPendingSectionHeaderRedline = (sectionHeaderId, redlineIndex) => {
    return services.runs.acceptPendingSectionHeaderRedline(run, userId, sectionHeaderId, redlineIndex);
  };

  const onAddStepIssue = useCallback(
    (issue, stepId, sectionId) => {
      return services.runs.addStepIssue(run?._id, sectionId, stepId, issue);
    },
    [run, services.runs]
  );

  const onAddRunIssue = useCallback(
    (issue) => {
      return services.runs.addRunIssue(run?._id, issue);
    },
    [run, services.runs]
  );

  const saveNewComment = useCallback(
    (comment, commentContext) => {
      if (!services.runs || !run) {
        return null;
      }
      return addStepComment({
        teamId: currentTeamId,
        run,
        userId,
        sectionId: commentContext.sectionId,
        stepId: commentContext.stepId,
        contentId: commentContext.contentId,
        rowIndex: commentContext.rowIndex,
        columnIndex: commentContext.columnIndex,
        comment,
        isRunEnded: run.state === RUN_STATE.COMPLETED,
      });
    },
    [services.runs, addStepComment, currentTeamId, run, userId]
  );

  const saveEditComment = useCallback(
    (comment, commentContext) => {
      if (!services.runs || !run) {
        return null;
      }

      return editStepComment({
        teamId: currentTeamId,
        run,
        userId,
        sectionId: commentContext.sectionId,
        stepId: commentContext.stepId,
        contentId: commentContext.contentId,
        rowIndex: commentContext.rowIndex,
        columnIndex: commentContext.columnIndex,
        comment,
        isRunEnded: run.state === RUN_STATE.COMPLETED,
      });
    },
    [services.runs, editStepComment, currentTeamId, run, userId]
  );

  const saveStepAfter = useCallback(
    (sectionId, precedingStepId, step, isRedline) => {
      if (!run) {
        return;
      }
      void addStepAfter({ teamId: currentTeamId, runId: run._id, userId, sectionId, precedingStepId, step, isRedline });
    },
    [run, addStepAfter, currentTeamId, userId]
  );

  const runStatus = useMemo(() => {
    return runUtil.getRunStatus(run);
  }, [run]);

  const runStepCounts = useMemo(() => {
    return runUtil.getRunStepCounts(run);
  }, [run]);

  const onEndRun = async (comment, status) => {
    if (!run) {
      return Promise.reject();
    }

    // Build user confirmation message
    let shouldConfirm = false;
    const messageTitle = 'Are you sure you want to end this procedure?';
    let message = '';
    const endLinkedProceduresMessage = '\nRunning linked procedures will end.';
    const incompleteStepsMessage = '\nIncomplete steps will be marked skipped.';
    const linkedProcedureIds = runUtil.getLinkedProcedureRunIds(run);

    // Warn: If run has some running linked procedures
    if (linkedProcedureIds.length > 0) {
      shouldConfirm = true;
      message = message.concat(endLinkedProceduresMessage);
    }

    // Warn: If run has some unfinished steps
    if (runUtil.runHasUnfinishedSteps(runStepCounts?.runCounts)) {
      shouldConfirm = true;
      message = message.concat(incompleteStepsMessage);
    }

    if (shouldConfirm && !window.confirm(messageTitle.concat(message))) {
      return Promise.reject();
    }
    const recordedTelemetry = getRecordedTelemetryAllSteps();
    const recordedContent = getLiveContentForAllSections(recordedTelemetry);
    const navigateToSource = () => {
      if (sourceLink) {
        history.push(sourceLink);
      } else if (sourceOperationLink) {
        history.push(sourceOperationLink);
      } else if (run.procedure_type === 'testPlan' || run.procedure_type === 'testSequence') {
        history.push(testingPlansPath(currentTeamId));
      } else {
        history.push(runsPath(currentTeamId));
      }
    };

    return endRun(currentTeamId, run, userId, recordedContent, comment, status).then(navigateToSource);
  };

  const [telemetryParameters, setTelemetryParameters] = useState<TelemetryParams | undefined>(undefined);

  const activeTelemetryInRun = useMemo(() => {
    if (!run) {
      return [];
    }
    const alwaysStream = getSetting(STREAM_TELELMETRY_FOR_COLLAPSED_STEPS, true);
    let allTelemetry: RunStepBlock[] = [];
    run.sections.forEach((section) => {
      if (!alwaysStream && isCollapsedMap[section.id]) {
        return;
      }
      section.steps.forEach((step) => {
        if (isStepEnded(step)) {
          return;
        }
        if (!alwaysStream && isCollapsedMap[step.id]) {
          return;
        }
        const telemetryStep = step.content.filter((content) => content.type.toLowerCase() === 'telemetry');
        allTelemetry = [...allTelemetry, ...telemetryStep];
      });
    });
    return allTelemetry;
  }, [getSetting, isCollapsedMap, run]);

  useEffect(() => {
    (async () => {
      if (!run || !run.state || !runUtil.isRunStateActive(run.state) || !fetchedTelemetryParameters) {
        setTelemetryParameters(undefined);
        return;
      }

      const identifiers: TelemetryParams = {};
      if (activeTelemetryInRun.length > 0) {
        const telemetryMap = {};
        for (const param of fetchedTelemetryParameters) {
          telemetryMap[telemetryUtil.getParameterIdentifier(param)] = param;
        }

        activeTelemetryInRun.forEach((content) => {
          if (content.type.toLowerCase() !== 'telemetry') {
            return;
          }
          const telemetryContent = content as RunTelemetryBlock;
          if (telemetryContent.key === 'custom' && telemetryContent.expression) {
            const params = extractParameterNamesFromExpression(
              telemetryContent.expression,
              fetchedTelemetryParameters
            ).map((parameter) => ({
              name: parameter.name,
              dictionaryId: parameter.dictionary_id,
              refreshRateMs: parameter.refresh_rate_ms,
              ...(parameter.isSimulation ? { isSimulation: true } : {}),
            }));

            for (const param of params) {
              identifiers[telemetryUtil.getParameterIdentifier(param)] = param;
            }
            return;
          }

          // Add in simulated fields which are now delivered by the same mechanism as standard
          if (telemetryContent.name && SimulatedFields[telemetryContent.name]) {
            identifiers[telemetryContent.name] = {
              name: telemetryContent.name,
              dictionaryId: telemetryContent.dictionary_id,
              refreshRateMs: 1000,
              isSimulation: true,
            };
          }

          // Skip parameters that are not currently supported by the backend.
          if (!telemetryUtil.isStandardTelemetry(telemetryContent)) {
            return;
          }

          const id = telemetryUtil.getParameterIdentifier(telemetryContent);
          if (!telemetryMap[id]) {
            return;
          }
          identifiers[id] = {
            name: telemetryMap[id].name,
            dictionaryId: telemetryMap[id].dictionary_id,
            refreshRateMs: telemetryMap[id].refresh_rate_ms,
          };
        });
      }
      setTelemetryParameters(identifiers);
    })().catch((err) => apm.captureError(err));
  }, [activeTelemetryInRun, fetchedTelemetryParameters, run, services.telemetry]);

  // Save a procedure variable value.
  const onSaveVariable = useCallback(
    (variable) => {
      saveVariable(run, variable);
    },
    [saveVariable, run]
  );

  // Load previous run documents for redline edits
  useEffect(() => {
    if (!run || !services.runs) {
      return;
    }
    // Legacy runs do not have `procedureRev`
    if (!run.procedureRev) {
      setRedlineState({
        runs: null,
        enabled: false,
        loading: false,
      });
      return;
    }

    /*
     * For now, ignore any redline changes from previous run documents
     * TODO: Load previous run documents and check for redlines
     */
    setRedlineState({
      runs: null,
      enabled: true,
      loading: false,
    });
  }, [run, services.runs]);

  /*
   * Function that takes a recorded object and returns a recorded object whose fields contain
   * only keys and values corresponding to telemetry fields
   *
   * recorded: object mapping the indices of content objects in a step to their recorded values
   *
   * returns: object mapping the indices on only telemetry content objects in a step to their
   *          recorded values
   */
  const filterRecordedTelemetry = useCallback(
    (sectionIndex, stepIndex, recorded) => {
      const recordedTelemetry = {};
      if (run) {
        Object.keys(recorded).forEach((contentIndex) => {
          if (run.sections[sectionIndex].steps[stepIndex].content[contentIndex].type === 'telemetry') {
            recordedTelemetry[contentIndex] = recorded[contentIndex];
          }
        });
      }
      return recordedTelemetry;
    },
    [run]
  );

  const getRecordedTelemetrySection = useCallback(
    (sectionIndex) => {
      if (!run) {
        return null;
      }
      const recordedTelemetrySection = newValuesRecordedSection(run.sections[sectionIndex]);
      valuesRecordedRef.current?.sections[sectionIndex].steps.forEach((step, stepIndex) => {
        const recordedTelemetry = filterRecordedTelemetry(sectionIndex, stepIndex, step.recorded);
        recordedTelemetrySection.steps[stepIndex].recorded = recordedTelemetry;
      });
      return recordedTelemetrySection;
    },
    [run, filterRecordedTelemetry]
  );

  const getLiveRunStepsMetadata = useCallback(() => {
    const state = store.getState();

    if (!run) return;

    return run.sections.map((section) => {
      return section.steps.map((step) => {
        return selectRunStep(state, currentTeamId, run._id, section.id, step.id);
      });
    });
  }, [run, store, currentTeamId]);

  const updateRecordedMap = useCallback(
    (recordedMap, sectionIndex: number, section, getStepRecordings) => {
      const liveRunStepsMetadata = getLiveRunStepsMetadata();
      if (!liveRunStepsMetadata) return recordedMap;

      const state = store.getState();
      section.steps.forEach((step, stepIndex) => {
        if (isStepEnded(step)) return;

        // Step recordings are using references of recordedMap. So changes to stepRecordings affects recordedMap
        const stepRecordings = getStepRecordings(stepIndex);
        const liveStepContent = liveRunStepsMetadata[sectionIndex][stepIndex]?.content;
        step.content.forEach((contentBlock, contentIndex) => {
          const liveBlock = liveStepContent?.[contentIndex] as
            | RunStepBlockWithRecorded
            | RunFieldInputTableBlock
            | undefined;
          const recorded = (liveBlock as RunStepBlockWithRecorded)?.recorded;
          if (!stepRecordings[contentIndex] && recorded) {
            stepRecordings[contentIndex] = recorded;
          }

          if (liveBlock?.type === 'field_input_table') {
            const fieldInputTableValues = {};

            liveBlock.fields.forEach((field, fieldIndex) => {
              const recordedValue = (field as RunStepBlockWithRecorded)?.recorded;
              fieldInputTableValues[fieldIndex] = recordedValue ?? {};
            });

            stepRecordings[contentIndex] = fieldInputTableValues;
          }

          if (shouldRecordExpressionForBlock(contentBlock)) {
            const { value, display, references } = getExpressionResult({
              state,
              tokens: contentBlock.tokens,
              recorded: (contentBlock as RunExpressionBlock | RunTextBlock)?.recorded,
              findDefinedUnit: contentBlock.type === BlockTypes.Text ? findDefinedUnit : undefined,
              shouldEvaluate: contentBlock.type !== BlockTypes.Text,
            });
            stepRecordings[contentIndex] = { value, display, references };
          }
        });
      });
      return recordedMap;
    },
    [findDefinedUnit, getExpressionResult, getLiveRunStepsMetadata, store]
  );

  const getLiveContentSection = useCallback(
    (sectionIndex: number, recordedMap) => {
      const section = run?.sections[sectionIndex];
      if (!section) return recordedMap;

      return updateRecordedMap(recordedMap, sectionIndex, section, (index) => recordedMap.steps[index].recorded);
    },
    [run, updateRecordedMap]
  );

  const getLiveContentForAllSections = useCallback(
    (recordedMap) => {
      run?.sections.forEach((section, sectionIndex) => {
        recordedMap = updateRecordedMap(
          recordedMap,
          sectionIndex,
          section,
          (index) => recordedMap.sections[sectionIndex].steps[index].recorded
        );
      });

      return recordedMap;
    },
    [run, updateRecordedMap]
  );

  /*
   * Returns object with structure
   * { sections: [{ steps: [{ recorded: [{ contentIndex: recordedData } ]} ]} ]}
   * that contains recorded telemetry objects
   * TODO: refactor this data structure to emulate the run doc
   */
  const getRecordedTelemetryAllSteps = useCallback(() => {
    const recordedTelemetryAllSteps = newValuesRecorded(run);
    // This shouldn't happen because valuesRecordedRef and run are initialized next to each other
    if (!valuesRecordedRef.current) {
      return recordedTelemetryAllSteps;
    }
    valuesRecordedRef.current.sections.forEach((_section, sectionIndex) => {
      if (recordedTelemetryAllSteps) {
        const recorded = getRecordedTelemetrySection(sectionIndex);
        if (recorded) {
          recordedTelemetryAllSteps.sections[sectionIndex] = recorded;
        }
      }
    });
    return recordedTelemetryAllSteps;
  }, [run, getRecordedTelemetrySection]);

  const getRecordedTelemetryStep = useCallback(
    (sectionIndex, stepIndex) => {
      const step = valuesRecordedRef.current?.sections[sectionIndex].steps[stepIndex];
      const recordedTelemetry = filterRecordedTelemetry(sectionIndex, stepIndex, step?.recorded);
      return recordedTelemetry;
    },
    [filterRecordedTelemetry]
  );

  const onRecordValuesChanged = useCallback(
    (sectionId, stepId, contentId, recorded, fieldIndex) => {
      // Fail gracefully if any ids are missing, to allow viewing the procedure.
      if (!sectionId || !stepId || !contentId) {
        apm.captureError(
          new Error(
            `Missing ids when recording values: (sectionId: ${sectionId}, stepId: ${stepId}, contentId: ${contentId})`
          )
        );
        return;
      }
      const [section, sectionIndex] = displaySections.find(([section]) => section.id === sectionId);
      const stepIndex = section.steps.findIndex((step) => step.id === stepId);
      const contentIndex = section.steps[stepIndex].content.findIndex((block) => block.id === contentId);
      const block = section.steps[stepIndex].content[contentIndex];

      // Crash early and often.
      if (!block) {
        throw new Error('Block not found');
      }

      // Field Input Table
      if (!isNil(fieldIndex)) {
        const fieldBlock = block.fields[fieldIndex];
        if (!fieldBlock) {
          throw new Error('Block not found');
        }
      }

      // Store recorded values that are sent with the step signoff/complete actions.
      if (block.type.toLowerCase() === 'telemetry') {
        if (valuesRecordedRef.current) {
          valuesRecordedRef.current.sections[sectionIndex].steps[stepIndex].recorded[contentIndex] = recorded;
        }
        return;
      }

      const operatorRoles = auth.getOperatorRoles();
      updateBlock(currentTeamId, run, sectionId, stepId, contentId, recorded, operatorRoles, fieldIndex);
    },
    [displaySections, auth, updateBlock, currentTeamId, run]
  );

  const allOperatorRolesInRun = useMemo<Array<string>>(
    () => runUtil.getOperatorRolesInRun(run) as Array<string>,
    [run]
  );

  const onSignOff = useCallback(
    (sectionIndex, stepIndex, signoffId, operator, recorded, operatorRoles) => {
      const sectionId = run?.sections[sectionIndex].id;
      const stepId = run?.sections[sectionIndex].steps[stepIndex].id;
      const recordedTelemetry = getRecordedTelemetryStep(sectionIndex, stepIndex);
      const _operatorRoles = operatorRoles ?? auth.getOperatorRoles();

      recorded = { ...recorded, ...recordedTelemetry };
      signOffStep(currentTeamId, run, userId, sectionId, stepId, signoffId, operator, recorded, _operatorRoles);
    },
    [run, getRecordedTelemetryStep, auth, signOffStep, currentTeamId, userId]
  );

  const onPinSignOff = useCallback(
    async ({ sectionId, stepId, signoffId, operator, pinUser, pin, recorded }) => {
      if (!run) {
        return;
      }
      const { sectionIndex, stepIndex } = runUtil.getSectionAndStepIndices(run, sectionId, stepId);
      const recordedTelemetry = getRecordedTelemetryStep(sectionIndex, stepIndex);

      const recordedMerged = { ...recorded, ...recordedTelemetry };
      return pinSignOffStep({
        run,
        sectionId,
        stepId,
        signoffId,
        operator,
        pinUser,
        pin,
        recorded: recordedMerged,
      });
    },
    [run, getRecordedTelemetryStep, pinSignOffStep]
  );

  const onRevokeSignoff = useCallback(
    (sectionId, stepId, signoffId, operatorRoles) => {
      if (!run || !sectionId || !stepId || !signoffId) {
        return;
      }
      const _operatorRoles = operatorRoles ?? auth.getOperatorRoles();

      revokeStepSignoff({
        teamId: currentTeamId,
        run,
        userId,
        sectionId,
        stepId,
        userOperatorRoles: _operatorRoles,
        signoffId,
      });
    },
    [run, auth, revokeStepSignoff, currentTeamId, userId]
  );

  const onStepComplete = useCallback(
    (sectionId, stepId, recorded) => {
      if (!run) {
        return;
      }
      const sectionIndex = run.sections.findIndex((section) => section.id === sectionId);
      const stepIndex = run.sections[sectionIndex].steps.findIndex((step) => step.id === stepId);
      const recordedTelemetry = getRecordedTelemetryStep(sectionIndex, stepIndex);

      recorded = { ...recorded, ...recordedTelemetry };
      completeStep(currentTeamId, run, userId, sectionId, stepId, recorded);
    },
    [run, getRecordedTelemetryStep, completeStep, currentTeamId, userId]
  );

  const onFailStep = useCallback(
    (sectionIndex, stepIndex, recorded) => {
      if (!run) {
        return;
      }
      const recordedTelemetry = getRecordedTelemetryStep(sectionIndex, stepIndex);

      recorded = { ...recorded, ...recordedTelemetry };
      const sectionId = run.sections[sectionIndex].id;
      const stepId = run.sections[sectionIndex].steps[stepIndex].id;
      failStep(currentTeamId, run, userId, sectionId, stepId, recorded);
    },
    [run, failStep, currentTeamId, userId, getRecordedTelemetryStep]
  );

  const onStepDetailChanged = useCallback(
    (sectionId, stepId, field, value) => {
      if (!run) {
        return;
      }
      updateStepDetails(currentTeamId, run, userId, sectionId, stepId, field, value);
    },
    [run, updateStepDetails, currentTeamId, userId]
  );

  const latestPausedAction = useMemo(() => runUtil.getLatestPausedAction(run) || {}, [run]);

  const isStartedByUser = run && run.started_by && run.started_by.method === 'web';
  const isStartedByApi = run && run.started_by && run.started_by.method === 'api';

  const notifyRemainingStepOperators = useCallback(
    (stepId) => {
      if (!run) {
        return;
      }
      return services.runs.notifyRemainingStepOperators(run._id, stepId);
    },
    [services.runs, run]
  );

  const [notificationListenerConnected, setNotificationListenerConnected] = useState(false);
  useEffect(() => {
    // Show the notification icon if the team has a registered webhook
    const isUsingWebhooks = getSetting(API_WEBHOOK_URL_KEY, null);
    if (isUsingWebhooks) {
      setNotificationListenerConnected(true);
      return;
    }
    services.runs
      .isNotificationListenerConnected()
      .then((isConnected) => setNotificationListenerConnected(isConnected))
      .catch(() => {
        // ignore
      });
  }, [services.runs, getSetting]);

  const onEdit = (goToTab) => {
    if (isPreviewMode) {
      history.push(procedureEditPath(currentTeamId, paramId), { goToTab });
    }
  };

  const onReview = (path) => {
    if (isPreviewMode) {
      history.push(`${procedureReviewPath(currentTeamId, paramId)}${path}`);
    }
  };

  const mixpanelTrack = useCallback(
    (name, options) => {
      if (mixpanel && name) {
        mixpanel.track(name, options);
      }
    },
    [mixpanel]
  );

  const pendingProcedureId = useMemo(() => procedureUtil.getPendingProcedureIndex(run?.procedure_id), [run]);

  const saveReviewComment = useCallback(
    (comment) => {
      if (!services.procedures || !run) {
        return null;
      }
      mixpanelTrack('Review Comment Saved', { level: comment.parent_id ? 'Child' : 'Parent', previewMode });
      return services.procedures.addComment(pendingProcedureId, comment);
    },
    [mixpanelTrack, pendingProcedureId, previewMode, run, services.procedures]
  );

  const resolveReviewComment = useCallback(
    (commentId: string) => {
      if (!services.procedures || !run) {
        return null;
      }
      mixpanelTrack('Review Comment Resolved', { previewMode });
      return services.procedures.resolveComment(pendingProcedureId, commentId);
    },
    [mixpanelTrack, pendingProcedureId, previewMode, run, services.procedures]
  );

  const unresolveReviewComment = useCallback(
    (commentId: string) => {
      if (!services.procedures || !run) {
        return null;
      }
      mixpanelTrack('Review Comment Unresolved', { previewMode });
      return services.procedures.unresolveComment(pendingProcedureId, commentId);
    },
    [mixpanelTrack, pendingProcedureId, previewMode, run, services.procedures]
  );

  const [activeSidebarTabs, setActiveSidebarTabs] = useState(isPreviewMode ? [1] : [0, 1]);

  const updateActiveSidebarTabs = useCallback((event) => {
    setActiveSidebarTabs(event.index);
  }, []);

  const updateAutomationState = useCallback(() => {
    const currentStatus = run?.automation_status;

    if (currentStatus === 'running') {
      services.runs.stopAutomation(run?._id);
    } else {
      services.runs.startAutomation(run?._id);
    }
  }, [run, services.runs]);

  const backToProps = useMemo(() => {
    if (!parentReference) {
      return;
    }

    let backButtonTitle: React.ReactNode = '';
    if (parentReference.type === 'run') {
      if (!sourceRun) {
        return;
      }
      backButtonTitle = <RunLabel code={sourceRun.code} runNumber={sourceRun.run_number} runNumberBg="medium" />;
    } else if (parentReference.type === 'issue') {
      backButtonTitle = `Issue - ${parentReference.id}`;
    }

    return {
      title: backButtonTitle,
      onClick: () => {
        history.push(sourceLink);
      },
    };
  }, [sourceRun, sourceLink, parentReference, history]);

  const sourceName = useMemo(() => {
    if (run?.code && run?.code) {
      return `${run.code} - ${run.name}`;
    }

    return 'Untitled run';
  }, [run]);

  // If run is not found show message.
  if (runNotFound) {
    return <NotFound />;
  }
  return (
    <div className="w-full">
      {!loading && run && (
        <RunContextProvider
          run={run}
          viewMode={viewMode}
          isPreviewMode={isPreviewMode}
          showStepAction={showStepAction}
          setShowStepAction={setShowStepAction}
          currentStepId={currentStepId}
          setCurrentStepId={setCurrentStepId}
          telemetryParameters={telemetryParameters}
          fetchedTelemetryParameters={fetchedTelemetryParameters}
        >
          {/* Print footer content */}
          <div className="hidden print:block fixed bottom-1 text-xs">{printFooter}</div>
          <RunStickyHeader
            run={run}
            scrollTo={showStep}
            scrollToId={onScrollToId}
            expandAll={expandAllHeadersAndSections}
            collapseAll={collapseAllHeadersAndSections}
            setShowPauseModal={setShowPauseModal}
            endRun={onEndRun}
            viewMode={viewMode}
            setViewMode={setViewMode}
            projectId={run.project_id}
            isPreviewMode={isPreviewMode}
            onEdit={onEdit}
            onReview={onReview}
            onAddIssue={onAddRunIssue}
            onAutomationChange={updateAutomationState}
            backTo={backToProps}
          />

          <div>
            {showPauseModal && <PauseModal onClose={() => setShowPauseModal(false)} pauseRun={pauseRun} />}

            <div className="flex transition-all items-stretch lg:mx-auto">
              {/* Procedure content: headers, sections, steps */}
              <ProcedureContextProvider procedure={run} scrollTo={showStep}>
                <SidebarLayout
                  paddingTop={showRunPausedStickyHeader || showAutomationPauseStickyHeader ? 'pt-20' : 'pt-10'}
                >
                  <SidebarLayout.Sidebar>
                    <RunSidebar
                      run={run}
                      runStatus={runStatus}
                      runStepCounts={runStepCounts.runCounts}
                      isPreviewMode={isPreviewMode}
                      participantUserIds={participantUserIds}
                      isStartedByApi={isStartedByApi || false}
                      isStartedByUser={isStartedByUser || false}
                      showRunPausedStickyHeader={showRunPausedStickyHeader || false}
                      displaySections={displaySections}
                      activeSidebarTabs={activeSidebarTabs}
                      updateActiveSidebarTabs={updateActiveSidebarTabs}
                      RunTagsSelector={
                        <Tags
                          tags={runTagOptions}
                          selectedTags={selectedTags}
                          onSelectTag={onSaveTag}
                          onCreateTag={onSaveTag}
                          onRemoveTag={onRemoveTag}
                          disabled={isTagsSelectorDisabled}
                          permanentTags={procedureTags}
                        />
                      }
                      OperationSelector={
                        <OperationSelector
                          operationId={run.operation}
                          enabled={canChangeOperation}
                          onSaveOperation={onSaveOperationTag}
                          onClearOperation={onClearOperationTag}
                          canClearOperation={canClearOperation}
                          runState={run.state}
                        />
                      }
                    />
                  </SidebarLayout.Sidebar>
                  <SidebarLayout.Content>
                    <div className="px-4 pt-2">
                      {(showRunPausedStickyHeader || showAutomationPauseStickyHeader) && (
                        <PausedStickyHeader
                          pausedAction={latestPausedAction}
                          resumeRun={resumeRun}
                          projectId={run.project_id}
                          resumeAutomation={resumeAutomation}
                          run={run}
                        />
                      )}
                      <div aria-label="Procedure" role="region">
                        <div className="flex flex-col grow">
                          {/* min-w-0 on flex child fixes long words pushing the
                        sidebar out past the parent container. */}
                          <div className="min-w-0 grow gap-x-4 mb-2">
                            {/* Show in print only */}
                            <div className="print:block hidden">
                              <h1 className="flex flex-row gap-x-1 mb-0 break-words">
                                <span>{run.code}</span>
                                {run.run_number && <span className="ml-1 px-1 bg-slate-300">{run.run_number}</span>}
                                <span>{runUtil.displayName(run, config)}</span>
                              </h1>
                              {run.version && <div>Version {run.version}</div>}
                            </div>

                            <RunDescription />
                          </div>
                          <RunProcedureVariables
                            isEnabled={isRunInputEnabled}
                            variables={run.variables ?? []}
                            onSaveVariable={onSaveVariable}
                            onRefChanged={onScrollToRefChanged}
                            scrollMarginTopValueRem={stickyHeaderHeightRem}
                          />
                          {/* Part Build (BOM) */}
                          {run.part_list && <PartList content={run.part_list} isHidden={false} />}
                          {run.test_case_list && (
                            <div className="mt-2">
                              <SnippetSelector content={run.test_case_list} />
                            </div>
                          )}
                        </div>
                        <div className="flex">
                          <RunFilterProvider
                            displaySections={displaySections}
                            filterSelectedOperators={filterSelectedOperators}
                          >
                            <div>
                              <RunFilter
                                filterSelectedOperators={filterSelectedOperators}
                                setFilterSelectedOperators={setFilterSelectedOperators}
                                operatorRoles={allOperatorRolesInRun}
                                docState={run.state}
                              />
                              {/* Run Header */}
                              {run.headers && (
                                <div className="my-3">
                                  {run.headers.map((header) => {
                                    return (
                                      <ProcedureHeader
                                        key={header.id}
                                        projectId={run.project_id}
                                        header={header}
                                        isCollapsed={isCollapsedMap[header.id]}
                                        onCollapse={setIsCollapsed}
                                        docState={run.state}
                                        isRedlineFeatureEnabled={redlineState.enabled}
                                        saveHeaderRedline={onSaveHeaderRedline}
                                        onAcceptPendingRedline={acceptPendingHeaderRedline}
                                        onRefChanged={onScrollToRefChanged}
                                        scrollToBufferRem={stickyHeaderHeightRem}
                                        isPreviewMode={isPreviewMode}
                                        onResolveReviewComment={resolveReviewComment}
                                        onUnresolveReviewComment={unresolveReviewComment}
                                        saveReviewComment={saveReviewComment}
                                        comments={run.comments}
                                        showReviewComments={previewMode === PREVIEW_MODE.REVIEW}
                                      />
                                    );
                                  })}
                                </div>
                              )}
                              {/* table layout: [section, bullet and content 1, content 2 and checkbox] */}
                              <div className="flex">
                                <div>
                                  <table
                                    className="table-fixed w-full border-collapse"
                                    cellSpacing="0"
                                    cellPadding="0"
                                    border={0}
                                  >
                                    <thead>
                                      <tr>
                                        <th className="w-4"></th>
                                        <th className="w-auto"></th>
                                        <th className="w-64"></th>
                                      </tr>
                                    </thead>
                                    <>
                                      {displaySections.map(([section, sectionIndex]) => (
                                        <ProcedureSection
                                          key={`section.${sectionIndex}`}
                                          section={section}
                                          sectionIndex={sectionIndex}
                                          sectionKey={runUtil.displaySectionKey(
                                            run.sections,
                                            sectionIndex,
                                            getSetting('display_sections_as', 'letters')
                                          )}
                                          sourceName={sourceName}
                                          repeatKey={runUtil.displayRepeatKey(run.sections, sectionIndex)}
                                          runId={run._id}
                                          projectId={run.project_id}
                                          docState={run.state}
                                          operation={run.operation}
                                          onRepeatStep={onRepeatStep}
                                          onRepeatSection={onRepeatSection}
                                          isRepeatable={!runUtil.hasARepeat(run.sections, sectionIndex)}
                                          onSkipStep={onSkipStep}
                                          onSkipSection={onSkipSection}
                                          onSignOff={onSignOff}
                                          onPinSignOff={onPinSignOff}
                                          onRevokeSignoff={onRevokeSignoff}
                                          onStepComplete={onStepComplete}
                                          onFailStep={onFailStep}
                                          onRefChanged={onScrollToRefChanged}
                                          onStartLinkedRun={onStartLinkedRun}
                                          onRecordValuesChanged={onRecordValuesChanged}
                                          isRedlineFeatureEnabled={redlineState.enabled}
                                          onSaveRedlineBlock={onSaveRedlineBlock}
                                          onSaveRedlineStepField={onSaveRedlineStepField}
                                          onSaveRedlineStepComment={onSaveRedlineStepComment}
                                          onAcceptPendingRedline={acceptPendingStepRedline}
                                          saveSectionHeaderRedline={saveSectionHeaderRedline}
                                          acceptPendingSectionHeaderRedline={acceptPendingSectionHeaderRedline}
                                          saveNewComment={saveNewComment}
                                          editComment={saveEditComment}
                                          addStepAfter={saveStepAfter}
                                          isCollapsedMap={isCollapsedMap}
                                          onCollapse={setIsCollapsed}
                                          onExpandCollapseAllSteps={() =>
                                            setAllStepsInSectionExpanded(
                                              !allStepsInSectionExpandedMap?.[sectionIndex],
                                              section
                                            )
                                          }
                                          allStepsAreExpanded={
                                            allStepsInSectionExpandedMap && allStepsInSectionExpandedMap[sectionIndex]
                                          }
                                          scrollToBufferRem={stickyHeaderHeightRem}
                                          notifyRemainingStepOperators={notifyRemainingStepOperators}
                                          notificationListenerConnected={notificationListenerConnected}
                                          stepCounts={runStepCounts.sectionCounts.get(section.id)}
                                          runStatus={runStatus}
                                          isPreviewMode={isPreviewMode}
                                          onAddStepIssue={(issue, stepId) => onAddStepIssue(issue, stepId, section.id)}
                                          areRedlineCommentsExpanded={areRedlineCommentsExpanded}
                                          expandRedlineComments={expandRedlineComments}
                                          onResolveReviewComment={resolveReviewComment}
                                          onUnresolveReviewComment={unresolveReviewComment}
                                          saveReviewComment={saveReviewComment}
                                          comments={run.comments}
                                          showReviewComments={previewMode === PREVIEW_MODE.REVIEW}
                                          isStrictSignoffEnabled={run.is_strict_signoff_enabled}
                                          configurePartKitBlock={configurePartKitBlock}
                                          configurePartBuildBlock={configurePartBuildBlock}
                                          onStepDetailChanged={onStepDetailChanged}
                                        />
                                      ))}
                                    </>
                                    <tfoot>
                                      <tr>
                                        <td>
                                          {/* place holder for the fixed-position footer */}
                                          <div className="h-14"></div>
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </RunFilterProvider>
                        </div>

                        {viewMode === VIEW_MODES.SINGLE_CARD && currentStepId === INTRO_STEP_KEY && (
                          <div className="mt-6 flex justify-center">
                            <FirstStepButton />
                          </div>
                        )}

                        <EndOfRunContent />
                      </div>
                    </div>
                  </SidebarLayout.Content>
                </SidebarLayout>

                {/* Procedure table of contents */}
              </ProcedureContextProvider>
            </div>
          </div>
        </RunContextProvider>
      )}
    </div>
  );
};

export default Run;
