import React, { useMemo } from 'react';

/**
 * Renders a pill for a returned Results.
 *
 * @param success - Success state used as boolean to determine label message
 * @param successText - Text used for showing Success label message
 * @param failText - Text used for showing Failed label message
 */
interface ResultsLabelProps {
  success: boolean;
  successText: string;
  failText: string;
}

const ResultsLabel = ({ success, successText, failText }: ResultsLabelProps) => {
  const statusString = useMemo(() => {
    return success ? successText : failText;
  }, [failText, success, successText]);

  const statusColor = useMemo(() => {
    return success ? 'bg-green-200' : 'bg-red-200';
  }, [success]);

  return (
    <div
      className={`w-10 py-0.5 h-fit text-center font-medium text-gray-800 text-xs group rounded-xl cursor-default ${statusColor}`}
    >
      {statusString}
    </div>
  );
};

export default ResultsLabel;
