import { Issue, IssueRow } from '../types';
import { Severity, StatusType } from 'shared/lib/types/postgres/issues';
import { Option } from 'react-select';
import contains from '../../lib/search';
import { Projects } from 'shared/lib/types/couch/settings';
import projectUtil from '../../lib/projectUtil';

const getSeverityOptions = (
  severities: Array<Severity> | undefined
): Array<Option> => {
  if (!severities) {
    return [];
  }
  const options: Array<Option> = [];
  for (const severity of severities) {
    options.push({
      value: severity.id,
      label: severity.name,
    });
  }
  return options.sort((a, b) => (a.value > b.value ? -1 : 1));
};

export const countOpenIssues = (issues: Array<Issue>): number => {
  const openIssues = issues.filter(isOpenIssue);
  return openIssues.length;
};

export const countClosedIssues = (issues: Array<Issue>): number => {
  const closedIssues = issues.filter(isClosedIssue);
  return closedIssues.length;
};

export const isOpenIssue = (issue: Issue): boolean => {
  return isDraftIssue(issue) || isActiveIssue(issue);
};

export const isDraftIssue = (issue: Issue): boolean => {
  return issue.status.status === StatusType.Draft;
};

export const isActiveIssue = (issue: Issue): boolean => {
  return issue.status.status === StatusType.Active;
};

export const isClosedIssue = (issue: Issue): boolean => {
  return issue.status.status === StatusType.Closed;
};

export const search = (
  issues: Array<Issue>,
  searchTerm: string,
  projects: Projects | null
): Array<Issue> => {
  return issues.filter((issue) => matches(issue, searchTerm, projects));
};

const matches = (
  issue: Issue,
  searchTerm: string,
  projects: Projects | null
): boolean => {
  const searchFields = [
    issue.title,
    issue.assignee,
    issue.id,
    issue.severity?.name,
    issue.referenceType,
    projectUtil.getProjectName(projects, issue.projectId),
  ];
  const searchStrings = searchFields.filter(Boolean).map(String);
  return contains(searchStrings, searchTerm);
};

export const sortStatusColumn = (a: IssueRow, b: IssueRow): number => {
  let statusA = a.status?.status || '';
  let statusB = b.status?.status || '';
  if (a.status?.status === StatusType.Closed) {
    statusA = a.subStatus?.value || 'closed';
    statusB = b.subStatus?.value || 'closed';
  }
  return statusA.localeCompare(statusB);
};

export const REGEX_LINKED_RUN_STARTED = /^Linked Run (\w+) Started$/;

export const checkLinkedRunStarted = (action: string): boolean => {
  return REGEX_LINKED_RUN_STARTED.test(action);
};

export default getSeverityOptions;
