import Selector from '../elements/Selector';
import { useMemo } from 'react';
import { Option } from 'react-select';
import { SUPPORTED_OPERATIONS, SupportedOperation } from 'shared/lib/math';
import { RuleOperator } from 'shared/lib/types/views/procedures';

const BASIC_OPERATION_OPTIONS: Array<Option> = SUPPORTED_OPERATIONS.map((op) => ({
  value: op,
  label: op,
}));
const ALL_OPERATION_OPTIONS: Array<Option> = [
  ...BASIC_OPERATION_OPTIONS,
  {
    value: 'range',
    label: 'Range',
  },
];
interface FieldSetMathOperationProps {
  value?: RuleOperator;
  operationOptions?: Array<SupportedOperation>;
  operatorError?: string;
  isDisabled?: boolean;
  onChange: (option: Option) => void;
  description?: string;
}

// Renders dropdown for math operations
const FieldSetMathOperation = ({
  value,
  operationOptions,
  operatorError,
  isDisabled,
  onChange,
  description,
}: FieldSetMathOperationProps) => {
  const selectOptions = useMemo(() => {
    return operationOptions
      ? operationOptions.map((op) => ({
          value: op,
          label: op,
        }))
      : ALL_OPERATION_OPTIONS;
  }, [operationOptions]);

  const selectedOption = useMemo(() => {
    return ALL_OPERATION_OPTIONS.find((option) => option.value === value);
  }, [value]);

  return (
    <div className="flex flex-col">
      <span className="field-title">{description ?? 'Op'}</span>
      <Selector
        description="Operation"
        isDisabled={Boolean(isDisabled)}
        name="Operation"
        onChangeHandler={onChange}
        selectOptions={selectOptions}
        selectedOption={selectedOption}
        type="standalone"
        placeholder="?"
        fitValue={true}
      />
      {operatorError && <div className="text-red-700">{operatorError}</div>}
    </div>
  );
};

export default FieldSetMathOperation;
