import { isStepEnded } from './runUtil';
import stepConditionals from './stepConditionals';
import {
  Procedure,
  Section,
  SourceConditionalsMap,
  Step,
  StepConditionalDiffElement,
} from './types/views/procedures';

type Vertex = {
  type: 'step' | 'section';
  id: string;
};

type EdgeType = 'conditional' | 'dependency';

class ProcedureGraph {
  incoming: Map<string, Map<string, EdgeType>>;
  vertices: Map<string, Vertex>;
  stepMap: Map<string, Step>;
  sectionMap: Map<string, Section>;
  endedSet: Set<string>;
  unreachableMap: Map<string, boolean>;
  sourceStepConditionalsMap: SourceConditionalsMap;

  constructor(doc: Procedure) {
    this.incoming = new Map();
    this.vertices = new Map();
    this.stepMap = new Map();
    this.sectionMap = new Map();
    this.endedSet = new Set();
    this.unreachableMap = new Map();
    this.sourceStepConditionalsMap = {};

    if (!doc) {
      return;
    }

    for (const section of doc.sections) {
      this.addVertex({ id: section.id, type: 'section' });
      this.sectionMap.set(section.id, section);

      let isSectionEnded = true;
      for (const step of section.steps) {
        this.addVertex({ id: step.id, type: 'step' });
        this.stepMap.set(step.id, step);

        if (isStepEnded(step)) {
          this.endedSet.add(step.id);
        } else {
          isSectionEnded = false;
        }

        if (step.conditionals) {
          for (const conditional of step.conditionals) {
            this.addEdge(step.id, conditional.target_id, 'conditional');
            if (!this.sourceStepConditionalsMap[conditional.target_id]) {
              this.sourceStepConditionalsMap[conditional.target_id] = {};
            }
            this.sourceStepConditionalsMap[conditional.target_id][
              conditional.id
            ] = conditional as StepConditionalDiffElement;
          }
        }
        if (step.dependencies) {
          for (const dependency of step.dependencies) {
            for (const dependentId of dependency.dependent_ids) {
              this.addEdge(dependentId, step.id, 'dependency');
            }
          }
        }
      }
      if (isSectionEnded) {
        this.endedSet.add(section.id);
      }
    }
  }

  private getConditionalParentIds(id: string) {
    const deps = this.incoming.get(id);

    return [...(deps?.entries() ?? [])]
      .filter(([, type]) => type === 'conditional')
      .map(([id]) => id);
  }

  private areConditionalsFulfilled(stepId: string) {
    const conditionalParentIds = this.getConditionalParentIds(stepId);

    if (conditionalParentIds.length === 0) {
      return true;
    }

    // Only one conditional is needed to fulfill a step's conditional requirements if multiple are present
    return conditionalParentIds.some((parentId) => {
      const parentStep = this.stepMap.get(parentId);
      if (!parentStep) {
        return false;
      }
      if (this.endedSet.has(parentId)) {
        const conditionals = parentStep.conditionals?.filter(
          (conditional) => conditional.target_id === stepId
        );
        return conditionals?.some((conditional) =>
          stepConditionals.isConditionalFulfilled(parentStep, conditional)
        );
      }
      return false;
    });
  }

  private isConditionalUnreachable(sourceId: string, targetId: string) {
    const parentStep = this.stepMap.get(sourceId);
    if (
      !parentStep ||
      !parentStep.conditionals ||
      !this.endedSet.has(parentStep.id)
    ) {
      return false;
    }
    const conditionals = parentStep.conditionals.filter(
      (conditional) => conditional.target_id === targetId
    );
    return (
      conditionals.length > 0 &&
      !conditionals.some((conditional) =>
        stepConditionals.isConditionalFulfilled(parentStep, conditional)
      )
    );
  }

  private areDependenciesFulfilled(stepId: string) {
    const step = this.stepMap.get(stepId);
    if (!step) {
      return false;
    }

    if (step && step.dependencies && step.dependencies.length) {
      // Use "every" because each dependency block is an AND condition
      return step.dependencies.every((dependency) => {
        if (dependency?.dependent_ids && dependency.dependent_ids.length) {
          // Use "some" because each dependency within a block is an OR condition
          return dependency.dependent_ids.some((dependentId) =>
            this.isVertexEnded(dependentId)
          );
        }
        return true;
      });
    }
    return true;
  }

  areRequirementsMet(stepId: string) {
    const deps = this.incoming.get(stepId);
    if (!deps || deps.size === 0) {
      return true;
    }

    return (
      this.areConditionalsFulfilled(stepId) &&
      this.areDependenciesFulfilled(stepId)
    );
  }

  private isVertexEnded(id: string) {
    const vertex = this.vertices.get(id);
    if (vertex?.type === 'step') {
      return this.endedSet.has(id);
    } else if (vertex?.type === 'section') {
      return this.isSectionEnded(id);
    }
    return false;
  }

  private isSectionEnded(sectionId: string): boolean {
    const section = this.sectionMap.get(sectionId);
    if (!section) {
      return false;
    }

    if (this.isVertexUnreachable(sectionId)) {
      return false;
    }

    // All steps are either ended or unreachable, but all steps cannot be unreachable
    return (section.steps as Array<Step>).every(
      (step) => this.endedSet.has(step.id) || this.isVertexUnreachable(step.id)
    );
  }

  // unreachable represents the branch of a procedure that cannot complete due to unmet conditionals
  private isVertexUnreachable(id: string, seen = new Set()): boolean {
    const memoized = this.unreachableMap.get(id);
    if (memoized !== undefined) {
      return memoized;
    }
    if (seen.has(id)) {
      return false;
    }
    seen.add(id);

    let isUnreachable = false;
    if (this.vertices.get(id)?.type === 'step' && this.incoming.get(id)?.size) {
      // a step is unreachable if every conditional and dependency is unreachable
      const conditionalParentIds = this.getConditionalParentIds(id);
      let allConditionalsAreUnreachable = false;
      if (conditionalParentIds.length) {
        allConditionalsAreUnreachable = conditionalParentIds.every((parentId) =>
          this.isConditionalUnreachable(parentId, id)
        );
      }

      let someDependenciesAreUnreachable = false;
      const step = this.stepMap.get(id);
      if (step && step.dependencies && step.dependencies.length) {
        // Use "some" because any unreachable dependency will cause this step to be unreachable
        someDependenciesAreUnreachable = step.dependencies.some(
          (dependency) => {
            if (dependency?.dependent_ids && dependency.dependent_ids.length) {
              // Use "every" because each dependency within a block is an OR condition
              return dependency.dependent_ids.every((dependentId) =>
                this.isVertexUnreachable(dependentId, seen)
              );
            }
            return false;
          }
        );
      }
      isUnreachable =
        allConditionalsAreUnreachable || someDependenciesAreUnreachable;
    } else if (this.vertices.get(id)?.type === 'section') {
      const section = this.sectionMap.get(id);
      if (section) {
        isUnreachable = (section.steps as Array<Step>).every((step) =>
          this.isVertexUnreachable(step.id, seen)
        );
      }
    }
    this.unreachableMap.set(id, isUnreachable);
    return isUnreachable;
  }

  private addVertex(vertex: Vertex) {
    if (!this.vertices.has(vertex.id)) {
      this.vertices.set(vertex.id, vertex);
    }
  }

  private addEdge(
    sourceId: string,
    targetId: string,
    edge: 'conditional' | 'dependency'
  ) {
    if (!this.incoming.get(targetId)) {
      this.incoming.set(targetId, new Map());
    }
    this.incoming.get(targetId)?.set(sourceId, edge);
  }
}

export default ProcedureGraph;
